import React, { useState, useEffect } from 'react'
import querystring from 'query-string'
import { toast } from 'react-toastify'

import './register.scss'

import Layout from '../components/Layout'
import { onlyNumber } from '../utilities/only-number'
import { RankSelect } from '../components/RankSelect'
import { getRanks } from '../services/api/get-ranks'
import { postUser } from '../services/api/users'
import { getInvitations } from '../services/api/invitations'

export default function RegisterPage ({ location }) {
  // obtener de la URL el token de invitación
  const queryParams = querystring.parse(location.search)
  const invitationToken = queryParams.invitation_token

  if (!invitationToken) {
    return (
      <Layout withoutHeader className='page-register'>
          El registro es permitido sólo con invitación
      </Layout>
    )
  }

  const [ranks, setRanks] = useState([])
  const [invitation, setInvitation] = useState()

  const [formValues, setFormValues] = useState({
    name: '',
    name2: '',
    lastname: '',
    lastname2: '',
    email: '',
    phone: '',
    rank_id: null,
    password: '',
    password_confirm: '',
    background_image: ''
  })

  // get invitation from backend
  useEffect(() => {
    let _invitation = null

    getInvitations({ token: invitationToken })
      .then(res => {
        if (res.status === 200) {
          _invitation = res.data.invitations[0]
          setInvitation(_invitation)

          const values = {
            name: _invitation.fullname.split(' ')[0],
            email: _invitation.email,
            email2: _invitation.email2,
            organigram_id: _invitation.organigram_id,
            force_id: _invitation.force_id,
            background_image: 'fondo-1',
            phone: _invitation.phone
          }

          // asigna la imagen de fondo que corresponda
          // Fuerza aérea (2); Armada (3)
          if ([2, 3].includes(_invitation.organigram_id)) {
            window.localStorage.setItem('ldv-background', 'fondo-2')
            values.background_image = 'fondo-2'
          }

          setFormValues({
            ...formValues,
            ...values
          })
        }
      })

      .then(async () => {
        const res = await getRanks({
          action: 'by_force_id',
          force_id: _invitation.force_id,
          format: 'treeFormat'
        })

        if (res.status === 200) {
          setRanks(res.data)
        } else {
          toast.error('No fue posible obtener los rangos')
        }
      })

      .catch(err => {
        console.dir(err)

        if (err.response) {
          toast.error(err.response.data.message)
        } else {
          toast.error('No fue posible obtener los datos de la invitación')
        }
      })
  }, [])

  // envía los datos al backend
  function onSubmit (e) {
    e.preventDefault()

    if (!formValues.rank_id) {
      toast.error('El rango es requerido')
      return
    } else if (formValues.password !== formValues.password_confirm) {
      toast.error('Las contraseñas no coinciden')
      return
    } else if (/^.{8,}$/.test(formValues.password) === false) {
      toast.error('La contraseña debe tener al menos 8 caracteres')
      return
    } else if (/[A-Z]+/.test(formValues.password) === false) {
      toast.error('La contraseña debe tener por lo menos una letra mayúscula')
      return
    } else if (/[a-z]+/.test(formValues.password) === false) {
      toast.error('La contraseña debe tener por lo menos una letra minúscula')
      return
    } else if (/\d+/.test(formValues.password) === false) {
      toast.error('La contraseña debe tener por lo menos un número')
      return
    } else if (/\W+/.test(formValues.password) === false) {
      toast.error('La contraseña debe tener por lo menos un caracter especial')
      return
    }

    const body = { ...formValues, token: invitationToken }
    delete body.password_confirm

    postUser({ body }).then(res => {
      if (res.status === 201) {
        toast.success('Listo, te avisaremos por email cuando tu cuenta sea aprobada por la Dirección General de Sanidad Militar')
        window.location.href = '/'
      }
    }).catch(error => {
      toast.error(error.response ? error.response.data.message : `${error}`)
    })
  }

  function onChange (e) {
    const name = e.target.name
    let value = e.target.value

    if (name === 'phone') {
      value = onlyNumber(value)
    }

    setFormValues({ ...formValues, [name]: value })
  }

  return (
    <Layout className='page-register'>
      <h1>REGISTRO</h1>

      <form
        onSubmit={onSubmit}
      >
        <div style={{ color: 'white', textAlign: 'center' }}>{invitation && invitation.email}</div>

        <div style={{ color: 'white', textAlign: 'center' }}>{invitation && invitation.email2}</div>

        <label>
          <div>NOMBRE:</div>
          <input
            required
            name='name'
            type='text'
            value={formValues.name}
            onChange={onChange}
            placeholder='NOMBRE'
            className='textfield'
            autoComplete='ño-completes'
          />
        </label>

        <label>
          <div>SEGUNDO NOMBRE:</div>
          <input
            name='name2'
            type='text'
            value={formValues.name2}
            onChange={onChange}
            placeholder='SEGUNDO NOMBRE'
            className='textfield'
            autoComplete='ño-completes'
          />
        </label>

        <label>
          <div>APELLIDO:</div>
          <input
            required
            name='lastname'
            type='text'
            value={formValues.lastname}
            onChange={onChange}
            placeholder='APELLIDO'
            className='textfield'
            autoComplete='ño-completes'
          />
        </label>

        <label>
          <div>SEGUNDO APELLIDO:</div>
          <input
            name='lastname2'
            type='text'
            value={formValues.lastname2}
            onChange={onChange}
            placeholder='SEGUNDO APELLIDO'
            className='textfield'
            autoComplete='ño-completes'
          />
        </label>

        <label>
          <div>TELÉFONO:</div>
          <input
            required
            name='phone'
            type='tel'
            pattern='[0-9]+'
            value={formValues.phone}
            onChange={onChange}
            placeholder='TELÉFONO'
            className='textfield'
            autoComplete='ño-completes'
          />
        </label>

        <label style={{ width: '100%' }}>
          RANGO
          <RankSelect
            required
            ranks={ranks}
            canSelectAny
            onChange={val => {
              if (typeof val === 'number' && !isNaN(val)) {
                setFormValues({
                  ...formValues,
                  rank_id: val
                })
              }
            }}
            value={formValues.rank_id}
            placeholder='RANGO'
            style={{
              width: '100%'
            }}
          />
        </label>

        <label>
          <div>PASSWORD:</div>
          <input
            required
            name='password'
            type='password'
            value={formValues.password}
            onChange={onChange}
            placeholder='PASSWORD'
            className='textfield'
            autoComplete='new-password'
          />
        </label>

        <label>
          <div>CONFIRMAR PASSWORD:</div>
          <input
            required
            name='password_confirm'
            type='password'
            value={formValues.password_confirm}
            onChange={onChange}
            placeholder='CONFIRMAR PASSWORD'
            className='textfield'
            autoComplete='new-password'
          />
        </label>

        <button type='submit'>ENVIAR</button>
      </form>
    </Layout>
  )
}
